<template>
  <main>
    <div class="container">
      <!-- Tabs for different categories -->
      <!-- <div class="tabs">
          <ul>
            <li :class="{ 'is-active': currentTab === '' }" @click="changeTab('')"><a>全部</a></li>
            <li :class="{ 'is-active': currentTab === '宏观资料' }" @click="changeTab('宏观资料')"><a>宏观</a></li>
            <li :class="{ 'is-active': currentTab === '策略资料' }" @click="changeTab('策略资料')"><a>策略</a></li>
            <li :class="{ 'is-active': currentTab === '行业资料' }" @click="changeTab('行业资料')"><a>行业</a></li>
            <li :class="{ 'is-active': currentTab === '个股资料' }" @click="changeTab('个股资料')"><a>个股</a></li>
            <li :class="{ 'is-active': currentTab === '其他资料' }" @click="changeTab('其他资料')"><a>其他</a></li>
          </ul>
        </div> -->
      <!-- Search field -->
      <!-- <input type="text" v-model="query.search" @input="debouncedSearch" class="input-field" placeholder="搜索"> -->

    </div>
    <c-scrollbar width="900px"
               height="530px" id="data-container"   ref="dataContainer">
       <ul>
        <input type="file" ref="fileInput">
        <button @click="uploadFile">上传资料</button>
      </ul>
      <ul v-if="!token">
        <p>请先点击右上角「三」登陆后重新打开table页</p>
      </ul>
      <ul v-else-if="filteredData">
        <li @click="openModal(item)" v-for="item in filteredData" :key="item.title" class="data-item">
          <div class="title">
            <div>{{ item.realTitle }}</div>
            <div class="icons">
              <img class="icon" @click="downloadFile($event, item)" src="@/assets/cloud-arrow-down-solid.svg" />
              <img @click="shareFile($event, item)" class="icon" src="@/assets/share-from-square-solid.svg" />
              <img @click="favFile($event, item)" class="icon" src="@/assets/star-solid.svg" />
              <img @click="delFile($event,item)" class="icon"  src="@/assets/trash-solid.svg" />
            </div>
          </div>
          <div class="details">
            <div class="detail">
              <span class="label">股票代码：</span>
              <span class="value">{{ item.stockCode }}</span>
            </div>
            <div class="detail">
              <span class="label">股票名称：</span>
              <span class="value">{{ item.stockName }}</span>
            </div>
            <div class="detail">
              <span class="label">报告机构：</span>
              <span class="value">{{ item.reportOrganization }}</span>
            </div>
            <div class="detail">
              <span class="label">报告时间：</span>
              <span class="value">{{ item.reportTime }}</span>
            </div>
            <div class="detail">
              <span class="label">大小：</span>
              <span class="value">{{ item.size }}</span>
            </div>
            <div class="detail">
              <span class="label">页数：</span>
              <span class="value">{{ item.pages }}页</span>
            </div>
          </div>
        </li>
        <button v-show="totalPage - 1 > currentPage && !loading" class="load-more-button" @click="loadMore()">加载更多</button>
        <label v-show="loading" class="label">加载中......</label>
      </ul>
      <ul v-else>
        <p>您还暂未上传资料</p>
        <p>1、您可以通过本栏目「上传资料」，把本地资料上传到栏目</p>
        <p>2、上传资料目前仅供本人跨平台查看，后续分享功能暂在研发中</p>
      </ul>
      
    </c-scrollbar>
    <div class="modal" :class="{ 'is-active': showModal }">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-content1">
        <embed :src="pdfUrl" type="application/pdf" style="width: 100%; height: 100vh;" />
      </div>
      <button class="modal-close is-large" aria-label="close" @click="closeModal"><img  src="@/assets/xmark-solid.svg"/></button>
    </div>
    <div class="modal" :class="{ 'is-active': showShare }">
      <div class="modal-background" @click="closeShare"></div>
      <div style="z-index: 999;">
        <img :src="shareUrl" />
      </div>
      <button class="modal-close is-large" aria-label="close" @click="closeShare"></button>
    </div> 
  </main>
</template>

<script>
import _ from 'lodash';
import {CScrollbar} from 'c-scrollbar';
export default {
  components: {
    CScrollbar,
  },
  data() {
    return {
      token: localStorage.getItem('token'),
      currentTab: '', // Tracks the current tab
      showModal: false,
      pdfUrl: 'https://research-report.dongh123.com/api/research_report/file',
      apiUrl: '/api/user/queryResearchReport',
      uploadFileUrl: '/api/user/addResearchReport',
      delFileUrl:'/api/user/delQueryResearchReport',
      favUrl: "/api/user/addFavoriteReport",
      qrUrl: "/api/generateQRCode",
      addUserHistoryUrl:'/api/user/addUserHistory',
      shareUrl: "",
      showShare:false,
      currentPage: 0,
      loading: false,
      totalPage: 1,
      jsonData: {
        totalPage: 1,
        count: 0,
        page: 0,
        list: []
      },
      query: {
        search: '', // Holds the search query
        pages: ''
      }
    };
  },
  mounted() {
    //   if (Object.values(this.query).some(value => value !== '')) {
    this.search();
    //   }
  },
  computed: {
    filteredData() {
      // let query = this.query;
      // return this.jsonData.list.filter(item => {
      //   return item.stockCode.includes(query.stockCode) &&
      //     item.stockName.includes(query.stockName) &&
      //     item.reportTime.includes(query.reportTime) &&
      //     item.reportOrganization.includes(query.reportOrganization);
      // });
      return this.jsonData.list;
    }
  },
  methods: {
    uploadFile() {
      const fileInput = this.$refs.fileInput;
      if (!fileInput.files[0]) {
        alert("Please select a file.");
        return;
      }

      const formData = new FormData();
      formData.append('file', fileInput.files[0]);

      fetch(`${this.uploadFileUrl}`, {
        method: 'POST',
        body: formData,
        headers: {
          Token: localStorage.getItem("token"),
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('上传文件失败');
          }
          return response.json();
        })
        .then(data => {
          console.log(data);
          this.jsonData.list = data.list;
          alert("上传文件成功!");
        })
        .catch(error => {
          console.error(error);
          alert("上传文件失败!");
        });
    },
    changeTab(tab) {
      this.currentTab = tab;
      // Perform search or any other action based on the tab if needed
      this.search();
    },
    shareFile(event, item) {
      console.log("shareFile,")
      const param = new URLSearchParams({
        title: item.title,
        size: item.size,
        type: item.type
      }).toString();
      const queryParams = new URLSearchParams({
        text: "https://research-report.dongh123.com/api/research_report/file?"+ param,
      }).toString();
      console.log(this.qrUrl+"?"+queryParams)
       
      // 文件下载链接 
      this.shareUrl = this.qrUrl+"?"+queryParams;
      this.showShare = true;
      event.stopPropagation();
    },
    closeShare(){
      this.showShare = false;
    },
    async delFile(event,item){
      fetch(`${this.delFileUrl}`, {
        method: 'POST',
        body: JSON.stringify({
          title: item.title,
          size: item.size,
          type: item.type,
        }),
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem("token"),
        }
      }).then(response => {
          if (!response.ok) {
            throw new Error('删除失败');
          }
          return response.json(); 
        })
        .then(data => {
          console.log(data);
          this.jsonData.list = data.list;
        })
        .catch(error => {
          console.error(error);
          alert("删除失败!");
        })
        event.stopPropagation();
    },
    async favFile(event, item) {
      fetch(`${this.favUrl}`, {
        method: 'POST',
        body: JSON.stringify({
          title: item.title,
          size: item.size,
          type: item.type,
        }),
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem("token"),
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('收藏失败');
          }
        })
        .then(data => {
          console.log(data);
          alert("收藏成功!");
        })
        .catch(error => {
          console.error(error);
          alert("收藏失败!");
        });
        event.stopPropagation();
    },
    async downloadFile(event, item) {
      fetch(`${this.addUserHistoryUrl}`, {
        method: 'POST',
        body: JSON.stringify({
          title: item.title,
          size: item.size,
          type: item.type,
        }),
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem("token"),
        }
      })
      const queryParams = new URLSearchParams({
        title: item.title,
        size: item.size,
        type: item.type,
        Token: localStorage.getItem("token"),
      }).toString();
      // 文件下载链接
      const fileUrl = `https://research-report.dongh123.com/api/research_report/downloadFile?${queryParams}`;
      window.open(fileUrl, '_blank');
      event.stopPropagation();
    },
    async search(isLoadMore) {
      this.loading = true;
      if (!isLoadMore) {
        this.currentPage = 0
      }
      const queryParams = new URLSearchParams({
        search: this.query.search,
        reportType: this.currentTab,
        page: this.currentPage,
        Token: localStorage.getItem("token"),
      }).toString();
      try {
        const response = await fetch(`${this.apiUrl}?${queryParams}`);
        const data = await response.json();
        if (isLoadMore) {
          this.jsonData.list = this.jsonData.list.concat(data.list)
        } else {
          this.jsonData.list = data.list;
        }
        // this.totalPage = data.totalPage;
        console.log(this.jsonData)
      } catch (error) {
        console.error('Error fetching content:', error);
      }
      this.loading = false;
    },
    async loadMore() {
      this.currentPage++;
      await this.search(true);
    },
    debouncedSearch: _.debounce(function () {
      this.search();
    }, 500),
    async openModal(item) {
      fetch(`${this.addUserHistoryUrl}`, {
        method: 'POST',
        body: JSON.stringify({
          title: item.title,
          size: item.size,
          type: item.type,
        }),
        headers: {
          'Content-Type': 'application/json',
          Token: localStorage.getItem("token"),
        }
      })
      try {
        const queryParams = new URLSearchParams({
          title: item.title,
          size: item.size,
          type: item.type,
        }).toString();

        this.pdfUrl = `https://research-report.dongh123.com/api/research_report/file?${queryParams}`
        console.info(this.pdfUrl);
        // 显示模态框
        this.showModal = true;
      } catch (error) {
        console.error('Error opening PDF:', error);
      }
    },
    closeModal() {
      // 关闭模态框
      this.showModal = false;
    }
  }
};
</script>

<style>
main {
  max-width: 800px;
  min-width: 800px;
  margin: 20px;
}

.load-more-button {
  display: block;
  margin: 16px auto;
  padding: 12px 24px;
  font-size: 16px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal {
  position: fixed;
  /* Fix it to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 9999;
  /* Ensure it's on top of other content */
}

.modal.is-active {
  display: flex;
  /* Display it when active */
  align-items: center;
  justify-content: center;
}

.modal-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content1 {
  position: relative;
  /* background-color: #fff; */
  /* padding: 20px; */
  /* border-radius: 5px; */
  width: 100%;
  /* max-width: 800px; */
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
}

#data-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-left: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.data-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
  cursor: pointer;
  color: #4285f4;
  ;
  /* 将标题字段字体颜色改为蓝色 */
  justify-content: space-between;
  display: flex;
}

.details {
  display: flex;
  flex-wrap: wrap;
}

.detail {
  margin-right: 20px;
  margin-bottom: 5px;
}

.label {
  font-weight: bold;
  font-size: 14px;
  color: #555;
}

.value {
  color: #555;
  font-size: 14px;
  /* 缩小非标题字段字体 */
}

.data-item {
  margin-bottom: 5px;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Align items to the start and end of the container */
  margin-bottom: 20px;
}

.container {
  margin-bottom: 15px;
  margin-top: 25px;
  margin-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-field {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 200px;
  /* Adjust the width as needed */
}

.tabs {
  /* Remove margin-left property */
}

.tabs ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  /* Align items horizontally */
}

.tabs ul li {
  margin-right: 10px;
  cursor: pointer;
}

.tabs ul li a {
  padding: 10px 15px;
  text-decoration: none;
  color: #555;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease-in-out;
}

.tabs ul li.is-active a {
  /* background-color: #4285f4; */
  border-bottom: 2px solid;
  color: #4285f4;
}

.icons {
  display: flex;
  justify-content: flex-end;
  /*图标靠右显示*/
  margin-top: 0.5rem;
  flex-wrap: nowrap;
  /* 防止图标换行 */
  overflow-x: auto;
  /* 如果图标过多，允许水平滚动 */
  -webkit-overflow-scrolling: touch;
}

.icon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
}
</style>