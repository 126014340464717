<template>
  <div :class="{ 'app-sidebar': true, 'collapsed': isCollapsed }">
    <!-- <div class="logo-container">
      <img src="../assets/logo.png" alt="Logo" class="logo" />
    </div> -->

    <!-- <button @click="toggleCollapse" class="collapse-btn" :aria-expanded="!isCollapsed" :aria-label="isCollapsed ? 'Expand' : 'Collapse'">
      <span class="icon-bar" v-for="n in 3" :key="n"></span>
    </button> -->
    <div class="sidebar-items">
      <div v-for="item in items" :key="item.id">
        <div v-if="item.id < 6" @click="selectItem(item)" :class="{ 'selected': selectedItemId === item.id }"
          class="sidebar-item">
          <i :class="['icon', { 'selected': selectedItemId === item.id }, item.iconClass]"></i>
          {{ item.name }}
        </div>
        <div v-if="isLogin">
          <div v-if="item.id === 6">
            <div :class="{ 'selected': selectedItemId === item.id }" class="sidebar-item" @click="controlSubMenu">
              <i :class="['icon', { 'selected': selectedItemId === item.id }, item.iconClass]"></i>
              {{ item.name }}
            </div>
            <div v-if="showSubMenu" :class="{ 'selected': item.sub === '按研报类型分类' }" class="sub-menu"
              @click="selectSubItem(item, '按研报类型分类')">
              按研报类型分类
            </div>
            <div v-if="showSubMenu" :class="{ 'selected': item.sub === '按研报机构分类' }" class="sub-menu"
              @click="selectSubItem(item, '按研报机构分类')">
              按研报机构分类
            </div>
          </div>
          <div v-if="item.id == 7" @click="selectItem(item)" :class="{ 'selected': selectedItemId === item.id }"
            class="sidebar-item">
            <i :class="['icon', { 'selected': selectedItemId === item.id }, item.iconClass]"></i>
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '../EventBus.js';
export default {
  name: "AppSidebar",
  data() {
    return {
      items: [
        { id: 1, name: "懂行研报", iconClass: "fas fa-book" },
        { id: 2, name: "懂行热点", iconClass: "fas fa-fire" },
        { id: 3, name: "懂行快讯", iconClass: "fas fa-newspaper" },
        { id: 4, name: "深度资讯", iconClass: "fas fa-book" },
        { id: 5, name: "懂行导航", iconClass: "fas fa-map" },
        { id: 6, name: "云收藏", iconClass: "fas fa-star" },
        { id: 7, name: "云资料", iconClass: "fas fa-upload" },
      ],
      isLogin:false,
      showSubMenu: false,
      isCollapsed: false,
      selectedItemId: 1,
    };
  },
  mounted() {
    this.selectedItemId = 1;
    this.$emit("itemSelected", this.items[0]);
    eventBus.on('isLogin',(isLogin)=>{
       this.isLogin = isLogin
    })
  },
  created(){
    eventBus.on('isLogin',(isLogin)=>{
       this.isLogin = isLogin
    })
  },
  methods: {
    controlSubMenu() {
      this.selectedItemId = 6;
      this.showSubMenu = !this.showSubMenu;
      if (this.showSubMenu) {
        this.selectSubItem(this.items[5], '按研报类型分类');
      }
    },
    selectItem(item) {
      this.selectedItemId = item.id;
      this.$emit("itemSelected", item);
      if (item.id !== 6) {
        this.showSubMenu = false;
      }
    },
    selectSubItem(item, sub) {
      item.sub = sub;
      this.selectedItemId = -1;
      this.$emit("itemSelected", item);

    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },

  },

};
</script>

<style scoped>
.app-sidebar {
  width: 250px;
  background-color: #fff;
  /* color: #fff; */
  overflow-x: hidden;
  transition: width 0.3s ease;
  background-image: url('../assets/left_bg.png');
  background-size: cover;
  background-repeat: repeat-y;
  /* Add transition effect */
}

.collapsed {
  width: 50px;
}

.collapse-btn {
  width: 50px;
  cursor: pointer;
  text-align: center;
  border: none;
  background-color: #394263;
  color: #fff;
  font-size: 20px;
  position: relative;
}

.collapse-btn:hover {
  background-color: #324556;
}

.icon-bar {
  display: block;
  width: 30px;
  height: 3px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

.collapsed .icon-bar:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.collapsed .icon-bar:nth-child(2) {
  opacity: 0;
}

.collapsed .icon-bar:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.logo-container {
  text-align: center;
  padding: 20px;
}

.logo {
  width: 200px;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain the aspect ratio */
}

.sidebar-items {
  list-style: none;
  padding-top: 30px;
  margin: 0;
}

.sidebar-item {
  cursor: pointer;
  /* background-color: rgb(241,242,252); */
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  padding: 30px;
  margin: 4px;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  padding-right: 20px;
  font-size: 15px;
}

.sidebar-item:hover {
  background-color: rgb(255, 255, 255);
  color: #376af6;
  border-radius: 8px;
}

.sidebar-item.selected {
  background-color: rgb(255, 255, 255);
  color: #376af6;
  font-weight: bold;
  border-radius: 8px;
}

.icon {
  margin-right: 10px;
  color: rgb(96, 103, 113);
}

.icon.hidden {
  display: none;
}

span {
  font-size: 14px;
}

.sub-menu:hover {
  background-color: rgb(255, 255, 255);
}

.sub-menu {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
  /* padding: 30px; */
  /* background-color: rgb(243, 244, 246); */
  color: rgb(96, 103, 113);
  border-radius: 8px;
  margin: 4px;
  padding-left: 70px;
  padding-bottom: 20px;
  padding-top: -24px;
  /* padding-right: 20px; */
  text-align: center;
}

.sub-menu.selected {
  background-color: rgb(255, 255, 255);
  color: rgb(57, 65, 80);
  font-weight: bold;
}
</style>
