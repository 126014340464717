<template>
  <div class="main_container">
    <div class="content">
      <div v-if="selectedItem.name === '懂行快讯'">
        <FlashNews />
      </div>
      <div v-if="selectedItem.name === '深度资讯'">
        <DeepNews />
      </div>
      <div v-if="selectedItem.name === '懂行导航'">
        <SiteNavigation />
      </div>
      <div v-if="selectedItem.name === '懂行热点'">
        <HotNews />
      </div>
      <div v-if="selectedItem.name === '懂行研报'">
        <ResearchReport />
      </div>
      <div v-if="loginStatus != 0">
      <div v-if="selectedItem.name === '云收藏'">
         <div v-if="selectedItem.sub === '按研报类型分类'">
          <UserResearchReportByType />
        </div>
        <div v-if="selectedItem.sub === '按研报机构分类'">
          <UserResearchReportByOrg />
        </div>
      </div>
      <div v-if="selectedItem.name === '云资料'">
        <UserUpdateResearchReport />
      </div>
    </div>
    </div>

   
  </div>
</template>

<script>
import FlashNews from "@/components/FlashNews.vue";
import DeepNews from "@/components/DeepNews.vue";
import SiteNavigation from "@/components/SiteNavigation.vue";
import HotNews from "@/components/HotNews.vue";
import ResearchReport from "@/components/ResearchReport.vue";
import UserUpdateResearchReport from "@/components/UserUpdateResearchReport.vue";
import UserResearchReportByOrg from "@/components/UserResearchReportByOrg.vue";
import UserResearchReportByType from "@/components/UserResearchReportByType.vue";

export default {
  name: "AppContent",
  props: {
    selectedItem: Object,
  },
  components: {
    FlashNews,
    DeepNews,
    SiteNavigation,
    HotNews,
    ResearchReport,
    UserUpdateResearchReport,
    UserResearchReportByOrg,
    UserResearchReportByType
  },
  mounted() {
    
  },
  data() {
    return {
      
    }
  },
  methods: {
  
  }
};
</script>

<style scoped>
.main_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* 使容器占据整个屏幕高度 */
}


.content {
  flex: 1;
  /* padding: 20px; */
}
</style>