<template>
  <div id="app">
    <div class="logo-container">
      <div class="user">
      <div class="bell">
        <i class="fa fa-bell-o fa-1x" aria-hidden="true"></i>
      </div>
      <div class="ph"><img alt="" :src="headImgUrl" v-if="headImgUrl"></div>
      <i style="padding: 0px 15px;">{{ userName }}</i>
      <div id=user_setting class="userSetting" v-if="userSettingShow">
        <ul>
          <!-- <li :class="'个人中心'=== nowWebType?cur_user:cur_user_default" v-on:click=userCenter()><i class="fa fa-user-circle-o" aria-hidden="true"></i> &nbsp &nbsp 个人中心</li>
              <li :class="'支付中心'=== nowWebType?cur_user:cur_user_default" v-on:click=payCenter()> <i class="fa fa-paypal" ></i> &nbsp &nbsp支付中心</li>
              <li class="cur_user_line" ></li> -->
          <li :class="'退出登录' === nowWebType ? cur_user : cur_user_default" v-on:click=logout()><i class="fa fa-sign-out"
              aria-hidden="false"></i> 退出登录</li>
        </ul>
      </div>
      <i id="prof" class="fa fa-bars fa-2x" style="padding: 0px 10px;cursor:pointer;line-height: 60px;" v-on:click=showLoginIfream()></i>
    </div>
    </div>
    <div class="content">
      <AppSidebar @itemSelected="handleItemSelected" />
      <AppContent :selectedItem="selectedItem"/>
      </div>
    <div class='bottom'>
      @2016-2024 dongh123.com dongh123.cn 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备16033156号-1</a>
  </div>
  </div>
 
   <!-- 遮罩层 -->
   <div class="mask" v-show="wxLoginShow" @click="hideLoginContainer"></div>
    <div id="login_container" class="wxlogin" v-show="wxLoginShow">
    </div>
</template>

<script>
import AppSidebar from "@/components/AppSidebar.vue";
import AppContent from "@/components/AppContent.vue";
import eventBus from "./EventBus.js";
export default {
  name: "App",
  data() {
    return {
      selectedItem: "导航",
      loginTimer: null,
      cur_user: 'cur_user',
      cur_user_default: 'cur_user_default',
      userSettingShow: false,
      wxLoginShow: false,
      headImgUrl: '',
      userName: '游客',
      loginStatus: 0,
      wxOpenId: '',
      wxUnionId: '',
    };
  },
  mounted(){
    let _this = this;
    if (localStorage.getItem("token") ) {
      //TODO根据token去后台获取用户信息，成功则认为登录有效
      let userInfo = localStorage.getItem("userInfo")
      userInfo = JSON.parse(userInfo)
      _this.userName = userInfo.userName;
      _this.loginStatus = 1;
      eventBus.emit('isLogin', true);
      _this.wxOpenId = userInfo.wxOpenId;
      _this.wxUnionId = userInfo.wxUnionId;
      _this.headImgUrl = userInfo.headImgUrl;
    }
    eventBus.on("wxLoginShow",(wxLoginShow)=>{
      if(wxLoginShow){
        _this.showLoginIfream();
      }else{
        _this.hideLoginContainer();
      }
     
    })
  },
  methods: {
    handleItemSelected(item) {
      this.selectedItem = item;
    },
    hideLoginContainer(event) {
      // 判断点击的区域是否在登录框内部
      const loginContainer = document.getElementById('login_container');
      if (loginContainer && !loginContainer.contains(event.target)) {
        // 点击区域不在登录框内部，则隐藏登录框
        this.wxLoginShow = false;
      }
    },
    showLoginIfream: function () {
      let _this = this
      console.log("showLoginIfream");
      if (_this.wxLoginShow) {
        _this.wxLoginShow = false
      } else if (_this.loginStatus === 0) {
        _this.wxLoginShow = true
      }
      if (_this.loginStatus === 1) {
        _this.userSettingShow = !_this.userSettingShow;
      }
      if (_this.loginStatus === 0 && _this.wxLoginShow) {

        //微信二维码登录
        // new WxLogin({ //eslint-disable-line
        //   self_redirect: true,
        //   id: "login_container",
        //   appid: "wx073cb5f1f9e1405c",
        //   scope: "snsapi_login",
        //   redirect_uri: "https%3A%2F%2Fwww.dongh123.com%2Fapi%2Fwx%2Flogin_callback",
        //   state: new Date(),
        //   style: "black",
        //   href: "https://dongh-wx-login-css.oss-cn-shanghai.aliyuncs.com/css/wx_login.css"
        // });

        // window.addEventListener('message', function (e) {
        //   var d = JSON.parse(decodeURIComponent(e.data));
        //   console.info(d)
        //   _this.wxLoginShow = false;
        //   _this.userName = d.userInfo.userName;
        //   _this.headImgUrl = d.userInfo.headImgUrl;
        //   _this.loginStatus = 1;
        //   _this.wxUnionId = d.userInfo.wxUnionId;
        //   _this.wxOpenId = d.userInfo.wxOpenId;
        //   eventBus.emit('isLogin', true);
        //   localStorage.clear();
        //   localStorage.setItem("userInfo", JSON.stringify(d.userInfo));
        //   localStorage.setItem("token", d.token);
        // });

        //微信公众号登录
       fetch(`/api/wxmp/login/scan`)
        .then(response => {
          console.log(response);
          if (!response.ok) {
            throw new Error('获取二维码失败');
          }
          return response.text();
        })
        .then(data => {
          console.log(data);
          let d = JSON.parse(data)
          let ticket = d.ticket
          let qrUrl = d.url
          document.getElementById("login_container").innerHTML='<img style="width: 150px; padding-top:150px" src="'+qrUrl+'" />'
          // 启动轮训查询登录结果
          _this.loginTimer = setInterval(() => {
            _this.checkLogin(ticket)
            }, 1000);
        }) 
        
      }
    },

    checkLogin: function(ticket){
      let _this = this
      const queryParams = new URLSearchParams({
        ticket: ticket,
      }).toString();
      fetch(`/api/wxmp/login/check?${queryParams}`)
      .then(response=>{
          if (!response.ok) {
            throw new Error('查询登录状态失败');
          }
          return response.json()
        })
      .then(d=>{
        console.log(d)
        if(d.userInfo){
          _this.wxLoginShow = false;
          _this.userName = d.userInfo.userName;
          _this.headImgUrl = d.userInfo.headImgUrl;
          _this.loginStatus = 1;
          _this.wxUnionId = d.userInfo.wxUnionId;
          _this.wxOpenId = d.userInfo.wxOpenId;
          eventBus.emit('isLogin', true);
          localStorage.clear();
          localStorage.setItem("userInfo", JSON.stringify(d.userInfo));
          localStorage.setItem("token", d.token);
          clearInterval(_this.loginTimer); 
        }
      })
    },
    logout: function () {
      let _this = this
      _this.nowWebType = "退出登录"
      _this.userSettingShow = false
      localStorage.setItem('token', '');
      localStorage.setItem("userInfo", '');
      _this.loginStatus = 0;
      _this.userName = '游客'
      _this.headImgUrl = ''
      _this.nowWebType = "首页"
      eventBus.emit("isLogin",false);
    }
  },
  components: {
    AppSidebar,
    AppContent,
  },
};
</script>

<style>
#app {
  display: flow-root;
  height: 100vh;
  margin-left: 3%;
  margin-right: 3%;
}
.logo-container{
  background-image: url('./assets/top_bar.png');
  background-repeat: round;
  /* height: 76.7px; */
}
.content{
  display: flex;
}
.user { 
  color: white;
  line-height: 53px;
  /* margin-top: 10px; */
  min-width: 800px;
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-left: 20px;
  margin-right: 20px;
  /* align-content: end; */
  justify-content: flex-end;

  i {
    display: block;
  }

  .ph {
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
}

.bell {
  /* width: 80px;
  height: 30px;
  margin-top: 17px; */
}
.wxlogin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 341px;
  height: 435px;
  transform: translate(-50%, -50%);
  /* 居中显示 */
  text-align: center;
  /* 文字居中 */
  background-image: url('./assets/login_bg.png');
  z-index: 999;
  background-repeat: round;
    background-size: contain;
    border-radius: 20px;
}
.userSetting {
      position: absolute;
      top: 63px;
      text-align: center;
      background: white;
      z-index: 999;
      min-width: 120px;
      font-size: 13px;
      border: 1px solid #e4ecf3;
      border-radius: 6px;
      .cur_user {
        /* color:  #477CE1; */
        line-height: 40px;
        height: 40px;
        cursor:pointer;
      }
      .cur_user_line {
        border-top: 1px solid #e4ecf3;
        margin-left: 10px;
        margin-right: 10px;
      }
      .cur_user_default {
        line-height: 40px;
        height: 40px;
        background-color: #477CE1;
        cursor:pointer;
      }
    }

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明黑色 */
  z-index: 998;
  /* 设置在登录框之下 */
}
.bottom{
  text-align: center;
  line-height: 63px;
}

/* Add additional styling as needed */
</style>
