<template>
    <main>
    <div class="container-tmp">
      <div class="tabs" id="tabs-container">
        <!-- Tabs will be displayed here -->
        <div
          v-for="(tabName, index) in tabs"
          :key="index"
          class="tab"
          @click="showTabContent(tabName)"
          :class="{ 'active-tab' : tabName === currentTab }"
        >
          {{ tabName }}
      </div>
    </div>

    <c-scrollbar  width="900px"
               height="530px" id="data-container">
      <!-- Data will be displayed here -->
      <div v-for="(newsItem, index) in newsList" :key="index" class="news-item">
        <a class="news-title" :href="newsItem.webUrl" target="_blank">{{ newsItem.title }} </a>
            <div class="news-content">{{ newsItem.content }}</div>
            <div class="source-and-time-container">
            <div class="news-source">来源：{{ newsItem.webTag }}</div>
            <div class="news-reptile-time">{{ newsItem.reptileTime }}</div>
            </div>
       
      </div>
      <button class="load-more-button" @click="loadMore">加载更多</button>
    </c-scrollbar>
  
      
  </div>
    </main>
  </template>
  
  <script>
import {CScrollbar} from 'c-scrollbar';
export default {
  components: {
    CScrollbar,
  },
    data() {
      return {
        apiUrl: '/api/zx/webUrl.shtml?webType=0004&webTag=',
        currentPage: 1,
        totalPage: 1,
        currentTab: '热点汇总',
        tabs: [
          '热点汇总',
          '热点事件',
          '雪球',
          '微博热搜',
          '网易新闻',
          '百度热搜',
          '哔哩哔哩',
          '头条热榜',
          '腾讯新闻',
          '36氪',
          '澎湃新闻',
          '人民日报',
          '豆瓣热映电影',
          '贴吧热议榜',
          '知乎日报',
          '知乎热榜',
          '少数派',
          '爱范儿',
        ],
        newsList: [],
      };
    },
    mounted() {
      this.fetchData(this.currentTab);
    },
    methods: {
      showTabContent(tabName) {
        this.currentPage = 1;
        this.currentTab = tabName;
        this.fetchData(tabName);
      },
      fetchData(tabName) {
         let webTag = tabName
        if(tabName === '热点汇总'){
          webTag = ''
        }
        fetch(`${this.apiUrl}${encodeURIComponent(webTag)}&page=${this.currentPage}`)
          .then((response) => response.json())
          .then((data) => {
            this.totalPage = data.totalPage;
            this.displayData(data.list);
          })
          .catch((error) => console.error('Error fetching content:', error));
      },
      displayData(data) {
        if (this.currentPage === 1) {
          this.newsList = [];
        }
  
        data.forEach((item) => {
          const newsItem = {
            title: item.webUrlTitle,
            content: item.content,
            webTag: item.webTag,
            webUrl: item.webUrl,
            reptileTime: new Date(item.reptileTime).toLocaleString(),
          };
  
          this.newsList.push(newsItem);
        });
  
        // this.hideLoadMore();
      },
      hideLoadMore() {
        const loadMoreButton = document.querySelector('.load-more-button');
        if (loadMoreButton) {
          loadMoreButton.remove();
        }
      },
      loadMore() {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
          this.currentPage++;
          if (this.totalPage >= this.currentPage) {
            this.fetchData(this.currentTab);
          }
        }
      },
    },
  };
  </script>
  
  <style scoped>
 
 main {
  max-width: 800px;
  margin: 20px auto;
}
  .container-tmp {
    /* width: 900px; */
    margin: 0 auto;
    padding: 10px;
  }
  
  .tabs-container {
    overflow-x: auto;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .tabs {
    display: flex;
    gap: 6px;
    padding: 1px;
    overflow-x: scroll;
    border-radius: 8px;
  }
  
  .tab {
    padding: 12px 20px;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s ease-in-out;
    color: #555; /* Inactive tab text color */
  }
  
  .tab:hover,
  .active-tab {
    border-bottom: 2px solid #4285f4;
    color: #4285f4; /* Active tab text color */
  }
  
  #data-container {
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    margin-top: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .news-item {
    margin-bottom: 16px;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  }
  
  .news-title {
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    color: #1a67b8;
    display: block;
    margin-bottom: 8px;
  }
  
  .news-content {
    font-size: 14px;
    color: #555; /* Content text color */
    margin-bottom: 8px;
  }
  
  .source-and-time-container {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #777; /* Source and time text color */
  }
  
  .load-more-button {
    display: block;
    margin: 16px auto;
    padding: 12px 24px;
    font-size: 16px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  </style>
  