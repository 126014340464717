<template>
      <main>
        <div class="container-tmp">
        <div class="tabs-container">
        <div class="tabs" >
          <!-- Tabs will be displayed here -->
          <div
            class="tab active-tab"
          >
          深度资讯
      </div>
      </div>
      </div>
    </div>
      <c-scrollbar width="900px"
               height="530px" class="content">
      <div id="news-list" class="news-list">
        <!-- News items will be dynamically added here -->
        <div v-for="newsItem in newsList" :key="newsItem.id" class="news-card">
          <a :href="newsItem.webUrl" class="news-title">{{ newsItem.webUrlTitle }}</a>
          <p class="news-meta">
            <span style="float: left;">{{ newsItem.webTag }}</span>
            <span style="float: right;">{{ new Date(newsItem.reptileTime).toLocaleString() }}</span>
          </p>
        </div>
      </div>
      <button v-show="totalPage - 1 > page && !loading" class="load-more-button" @click="fetchAndAppendNews()">加载更多</button>
      <div v-if="loading" class="loading">Loading...</div>
    </c-scrollbar>
    </main>
  </template>
  
  <script>
import {CScrollbar} from 'c-scrollbar';
export default {
  components: {
    CScrollbar,
  },
    name: "DeepNews",
    data() {
        return {
      newsList: [],
      loading: false,
      page: 1,
      totalPage:1,
    };
  },
  mounted() {
    this.fetchAndAppendNews();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    fetchAndAppendNews() {
      this.loading = true;

      fetch(`/api/zx/webUrl.shtml?page=${this.page}&webType=0002`)
        .then(response => response.json())
        .then(data => {
          this.newsList = [...this.newsList, ...data.list];
          this.page++;
          this.totalPage = data.totalPage;
          this.loading = false;
        })
        .catch(error => {
          console.error('Error fetching news data:', error);
          this.loading = false;
        });
    },

  },
  };
  </script>
  
  <style scoped>
  .content {
    flex: 1;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* margin-top: 65px; */
  }
  main {
    margin: 10px;
    padding-top: 20px;

}
.tab {
    padding: 12px 20px;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s ease-in-out;
    color: #555; /* Inactive tab text color */
  }
  
  .tab:hover,
  .active-tab {
    border-bottom: 2px solid #4285f4;
    color: #4285f4; /* Active tab text color */
  }
  
  
  .tabs {
    display: flex;
    gap: 6px;
    padding: 1px;
    overflow-x: scroll;
    border-radius: 8px;
  }
  .news-list{
    width: 900px;
  }
.news-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow: hidden;
  padding: 16px;
}

.news-item {
  padding: 15px;
  margin-bottom: 16px;
  border-radius: 8px;
  width: 900px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
}

.news-title {
  font-size: 16px;
  color: #0d81c4;
  text-decoration: none;
  transition: color 0.3s ease;
  display: block;
  margin-bottom: 10px;
}

.news-title:hover {
  color: #0066cc;
}

.news-meta {
    margin-bottom: auto;
    margin-top: 20px; 
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #777;
}

.loading {
  text-align: center;
  margin-top: 20px;
}

footer {
  margin-top: 20px;
  text-align: center;
  color: #777;
}

.news-content {
  color: #555;
}
  </style>
  