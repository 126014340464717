<template>
    <main>
    <div class="container-tmp">
        <div class="tabs-container">
      <div class="tabs" >
        <!-- Tabs will be displayed here -->
        <div
          v-for="(tabName, index) in tabs"
          :key="index"
          class="tab"
          @click="showTabContent(tabName)"
          :class="{ 'active-tab' : tabName === currentTab }"
        >
          {{ tabName }}
      </div>
      </div>
    </div>

    <c-scrollbar id="data-container" width="900px"
               height="530px">
      <!-- Data will be dynamically added here -->
      <div v-for="row in dataRows" :key="row.id" class="news-row">
        <div v-for="item in row.items" :key="item.id" class="news-item">
          <a v-if="item.webIconUrl" :href="item.webUrl" target="_blank" class="web-icon">
            <img :src="item.webIconUrl" alt="Web Icon" class="web-icon" />
          </a>
          <a :href="item.webUrl" class="news-title">{{ item.webTitle }}</a>
        </div>
      </div>
    </c-scrollbar>
  </div>
</main>
  </template>
  
  <script>
 import {CScrollbar} from 'c-scrollbar';
export default {
  components: {
    CScrollbar,
  },
    name: "FlashNews",
    data() {
    return {
      apiUrl: "/api/webIndexUrl.shtml?webType=",
      currentPage: 1,
      totalPage: 1,
      currentTab: "财经股票",
      tabs: [
        "财经股票",
        "财经社区",
        "行情软件",
        "美股投资",
        "港股投资",
        "期货黄金外汇",
        "报纸报刊",
        "实用工具",
        "官方机构",
        "在线量化平台",
      ],
      dataRows: [],
    };
  },
  computed: {
    showLoadMoreButton() {
      return this.totalPage >= this.currentPage;
    },
  },
  mounted() {
    
    this.fetchData(this.currentTab);
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    
    showTabContent(tabName) {
      this.currentPage = 1; // Reset page number
      this.currentTab = tabName;
      this.fetchData(tabName);
    },
    fetchData(tabName) {
      fetch(`${this.apiUrl}${encodeURIComponent(tabName)}&page=${this.currentPage}`)
        .then((response) => response.json())
        .then((data) => {
          this.totalPage = data.totalPage;
          this.displayData(data);
        })
        .catch((error) => console.error("Error fetching content:", error));
    },
    displayData(data) {
      if (this.currentPage === 1) {
        this.dataRows = [];
      }

      let currentRow = { id: this.dataRows.length + 1, items: [] };

      data.forEach((item,index) => {
        currentRow.items.push(item);

        // Create a new row after every three items
        if ((index + 1) % 5 === 0) {
          this.dataRows.push(currentRow);
          currentRow = { id: this.dataRows.length + 1, items: [] };
        }
      });

      // Append the last row if it is not complete
      if (currentRow.items.length > 0) {
        this.dataRows.push(currentRow);
      }
    },
    loadMore() {
      this.currentPage++;
      if (this.totalPage >= this.currentPage) {
        this.fetchData(this.currentTab);
      }
    },
    handleScroll() {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
        this.loadMore();
      }
    },
  },
};
  </script>
  
<style scoped>
  /* body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    color: #333;
  } */
  main {
  /* max-width: 800px; */
  margin-top: 20px ;
  margin-left: 20px ;
  margin-right: 20px ;
  margin-bottom: 0px;
}
  .container-tmp {
    /* max-width: 800px; */
    margin: 0 auto;
    border-left: 1px;
    padding: 10px;
    width: 900px;
  }
  
  .tabs-container {
    overflow-x: auto;
    background-color: #fff;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .tabs {
    display: flex;
    gap: 6px;
    padding: 1px;
  }
  
  .tab {
    padding: 12px 20px;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s ease-in-out;
    color: #555; /* Inactive tab text color */
  }
  
  .tab:hover,
  .active-tab {
    border-bottom: 2px solid #4285f4;
    color: #4285f4; /* Active tab text color */
  }
  
  #data-container {
    padding: 16px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .news-title {
    font-size: 14px;
    /* font-weight: bold; */
    text-decoration: none;
    color: #1a67b8;
    display: block;
    margin-bottom: 8px;
    margin-top: 10px;
  }
  
  .news-content {
    font-size: 14px;
    color: #555; /* Main content color */
    margin-bottom: 8px;
  }
  
  .source-and-time-container {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #777; /* Source and time text color */
  }
  
  .load-more-button {
    display: block;
    margin: 16px auto;
    padding: 12px 24px;
    font-size: 16px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .news-row {
    display: flex;
    flex-wrap: wrap;
    margin: -8px; /* Adjust margin to your liking */
  }
  
  .news-item {
    flex: 0 0 calc(20% - 16px); /* Adjust width for the number of columns */
    margin: 8px; /* Adjust margin to your liking */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align items horizontally */
    text-align: center; /* Center align text */
    border: 1px solid #ddd; /* Border style and color */
    border-radius: 8px; /* Optional: Add border-radius for rounded corners */
  }
  
  /* Additional style for web icons */
  .web-icon {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-right: 10px;
    cursor: pointer; /* Add cursor pointer for better UX */
    margin-top: 10px;
  }
  
  .web-icon a {
    display: block;
    width: 100%;
    height: 100%;
  }
  </style>
  
  